// TODO - need to fix these linting problems
/* eslint-disable no-underscore-dangle,operator-linebreak,implicit-arrow-linebreak */
/* eslint-disable no-param-reassign,no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions,no-use-before-define */
/* eslint-disable consistent-return,max-len */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './styles/App.css';
import './styles/form.css';
import './styles/utilities.css';
import 'basscss/css/basscss-important.css';
import './styles/open-color.css';
import 'bulma-extensions/bulma-tooltip/dist/css/bulma-tooltip.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTerminal } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import ThankYouPage from './components/thank-you-page';
import Console from './components/console';
import DataEntryPage from './components/data-entry';
import 'loaders.css/loaders.min.css';
import 'animate.css/animate.min.css';
import 'bulma-extensions/bulma-quickview/dist/css/bulma-quickview.min.css';
import './styles/drawer.css';
import logo from './assets/logo.svg';
import Notification from './components/notification';

import {
  CARD_NETWORKS_TO_USE,
  CHALLENGE_POSITIONS,
  EXECUTORS,
  FORTER_BACKENDS,
  FORTER_DECISIONS,
  SCHEMA_VALIDATION_MODES,
  FORTER_TOKENIZATION_ENVS,
  LOG_TYPES,
  NOTIFICATIONS,
  THREE_DS_VERSIONS,
} from './helpers/enums';
import { FLOW_DATA } from './helpers/flows';
import formHelper from './helpers/form-helper';
import products from './helpers/products';
import OrderTable from './components/order-table';
import StoreHeader from './components/store-header';
import { HEADER_SETTINGS_TO_HEADERS } from './settings';
import { getQueryStringValues, updateQueryString } from './helpers/querystring';
import currencies from './helpers/currencies';
import { isValidCC } from './helpers/validation';
import * as checkoutTools from './helpers/checkoutTools';
import { sleep } from './helpers/promises';
import apiCall from './helpers/calls';
import ChallengeWindow from './components/challenge-window';

const hasTooltip = window.innerWidth >= 992 ? 'tooltip' : '';

// TODO: Remove all these globals
let isCheckoutButtonReady = false;
let performingInit = false;
let pendingPayment;
let orderId;
let threeDSServerTransID;

const HEADERS_TO_SETTING_NAMES = _.invert(HEADER_SETTINGS_TO_HEADERS);

function App() {
  const [page, setPage] = useState('data-entry');
  const [log, setLog] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [challengeDisplay, setChallengeDisplay] = useState(CHALLENGE_POSITIONS.AJAX_REDIRECT);
  const [showChallengeInCheckout, setShowChallengeInCheckout] = useState(false);
  const [showChallengeInModal, setShowChallengeInModal] = useState(false);
  const [correlationId, setCorrelationId] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [isBackVisible, setisBackVisible] = useState(false);
  const [formFields, setFormFields] = useState(formHelper.getFormInitObj());
  const [showSideMenu, setShowSideMenu] = useState(true);
  const [tokenizationAuthToken, setTokenizationAuthToken] = useState('');
  const { settings: settingsFromQueryString, ...headersFromQueryString } = getQueryStringValues();
  const defaultSettings = {
    challengePosition: challengeDisplay.value,
    flow: '',
    enableCorrelationId: false,
    disableProductSelection: false,
    product: 'CHARGER',
    cardNetworkToUse: CARD_NETWORKS_TO_USE.DEFAULT.value,
    currency: currencies.USD,
    forterBackendEnv: FORTER_BACKENDS.USE.value,
    forterTokenizationEnv: FORTER_TOKENIZATION_ENVS.SANDBOX.value,
    executor: EXECUTORS.FORTER,
    tokenizationMode: false,
    networkTokenizationMode: false,
    managedOrdersIntegration: true,
    customChallengeElement: true,
  };
  const SETTINGS_TO_PERSIST = [
    'managedOrdersIntegration',
    'tokenizationMode',
    'networkTokenizationMode',
    'cardNetworkToUse',
    'currency',
    'forterBackendEnv',
    'forterTokenizationEnv',
  ];
  const [threeDsSettings, setThreeDsSettings] = useState({
    ...defaultSettings,
    ...settingsFromQueryString,
    headers: {
      proxyAuthorizationHeader: '',
      authorizationHeader: '',
      siteIdHeader: '',
      apiVersionHeader: '10.1',
      disableSchemaHeader: SCHEMA_VALIDATION_MODES.on,
      acquirerDataHeader: [],
      additionalInformationHeader: [],
      additionalIdentifiersHeader: [],
      forterSubMerchantSiteIdHeader: '',
      // hydrate headers from query params (while changing some names to keep backward compatability with the previous
      // way settings were taken from query parameters)
      ..._.mapKeys(headersFromQueryString, (_value, key) => HEADERS_TO_SETTING_NAMES[key] || key),
    },
    recurringPaymentCurrentPaymentNumber: null,
  });

  const updateThreeDsSettings = useCallback(
    (partialThreeDsSettings) =>
      setThreeDsSettings((currentThreeDsSettings) => {
        // Turn off tokenization mode when managed orders mode is turned off
        const updatedSettings = {
          ...currentThreeDsSettings,
          ...partialThreeDsSettings,
        };

        return {
          ...updatedSettings,
          tokenizationMode:
            updatedSettings.managedOrdersIntegration === false ? false : updatedSettings.tokenizationMode,
          networkTokenizationMode:
            updatedSettings.managedOrdersIntegration && updatedSettings.tokenizationMode
              ? updatedSettings.networkTokenizationMode
              : false,
        };
      }),
    [setThreeDsSettings],
  );

  useEffect(() => {
    // Persist header overrides to the url (while changing some names to keep backward compatability with the previous
    // way settings were taken from query parameters)
    const renamedHeaderSettings = _.mapKeys(
      threeDsSettings.headers,
      (_value, key) => HEADER_SETTINGS_TO_HEADERS[key] || key,
    );
    const settings = _.pickBy(
      threeDsSettings,
      (value, key) => SETTINGS_TO_PERSIST.includes(key) && value !== defaultSettings[key],
    );

    updateQueryString(settings, renamedHeaderSettings);
  }, [threeDsSettings.headers, ..._.values(_.pick(threeDsSettings, SETTINGS_TO_PERSIST))]);

  useEffect(() => {
    if (threeDsSettings.flow) {
      setData(threeDsSettings.flow);
    }
  }, [threeDsSettings.flow]);

  useEffect(() => {
    const doEffect = async () => {
      const { cc } = formFields;
      if (performingInit) {
        return;
      }

      if (isValidCC(cc)) {
        performingInit = true;

        try {
          await onCreditCardAvailable(formFields);
        } finally {
          performingInit = false;
        }
      }
    };

    doEffect();
  }, [formFields.cc]);

  useEffect(() => {
    const func = async () => {
      if (!threeDsSettings.tokenizationMode) {
        return;
      }

      pushLogEntry("(merchant server) POST tokenization '/v1/tokenization/client-key'", LOG_TYPES.MERCHANT);
      const { token } = await send({
        path: 'tokenization-auth-token',
      });
      setTokenizationAuthToken(token);
      if (token) {
        pushLogEntry(
          '(merchant server) Response received, passing auth token to the browser before rendering the page...',
          LOG_TYPES.MERCHANT,
        );
        pushLogEntry(`Hosted fields Auth Token: ${token}`, LOG_TYPES.BROWSER);
      } else {
        pushLogEntry('(merchant server) Error received, tokenization will not work correctly', LOG_TYPES.MERCHANT);
      }
    };

    func();
  }, [threeDsSettings.tokenizationMode]);

  const challengeContainer = useRef({});

  const send = async ({ method = 'post', path, body }) =>
    apiCall({
      method,
      path,
      body,
      forterBackendEnv: threeDsSettings.forterBackendEnv,
      forterTokenizationEnv: threeDsSettings.forterTokenizationEnv,
      headers: threeDsSettings.headers,
    })
      .then((resp) => resp.json())
      .catch((error) => pushLogEntry(`An error occurred: ${error}`, LOG_TYPES.ERROR));

  const buildInitPayload = useCallback(() => {
    const { enableCorrelationId, tokenizationMode } = threeDsSettings;
    const { cc, expiry, firstName, lastName } = formFields;
    let payload = { cc };
    if (tokenizationMode) {
      payload = { cc };
    }

    if (!enableCorrelationId) {
      return payload;
    }

    return {
      ...payload,
      expiry,
      firstName,
      lastName,
    };
  }, [threeDsSettings, formFields]);

  const onCreditCardAvailable = useCallback(async () => {
    isCheckoutButtonReady = false;
    if (!threeDsSettings.managedOrdersIntegration) {
      setIsNotificationVisible(true);
      setNotificationMessage(NOTIFICATIONS.INIT_3DS);
      const initPayload = buildInitPayload();
      pushLogEntry('(browser) POST 3ds-demo-store.forter.com/init', LOG_TYPES.BROWSER);
      pushLogEntry(JSON.stringify(initPayload, null, 4), LOG_TYPES.PAYLOAD);
      pushLogEntry('(merchant server) POST /v3/adaptive-auth/3ds/init', LOG_TYPES.MERCHANT);
      pushLogEntry('(browser) ...Waiting for response...', LOG_TYPES.BROWSER);
      const res = await send({
        path: 'init',
        body: initPayload,
      });

      const { response: initResponse } = res;

      setCorrelationId(initResponse.correlationId);
      orderId = res.orderId;

      pushLogEntry(
        '(merchant server) Response received, passing the response back to the browser:',
        LOG_TYPES.MERCHANT,
      );
      pushLogEntry(JSON.stringify(initResponse, null, 4), LOG_TYPES.PAYLOAD);
      pushLogEntry('(browser) Response received: ', LOG_TYPES.BROWSER);
      pushLogEntry(JSON.stringify(initResponse, null, 4), LOG_TYPES.PAYLOAD);

      if (!Object.values(THREE_DS_VERSIONS).includes(initResponse.version)) {
        setIsNotificationVisible(false);
        isCheckoutButtonReady = true;
        validate();

        return initResponse;
      }

      pushLogEntry('(browser) Calling checkoutTools.tds.init3DS(response, [callback])', LOG_TYPES.BROWSER);
      pushLogEntry('(browser) Sending data to issuer...', LOG_TYPES.BROWSER);
      setNotificationMessage(NOTIFICATIONS.SENDING_DATA_TO_ISSUER);
      try {
        setErrorMessage(undefined);
        threeDSServerTransID = await checkoutTools.init3DS(initResponse);

        pushLogEntry('(browser) Callback called - Issuer data collection has completed', LOG_TYPES.BROWSER);
        pushLogEntry(
          '(browser) Storing the threeDSServerTransID in a variable, it will be used in the order validation API call',
          LOG_TYPES.BROWSER,
        );

        isCheckoutButtonReady = true;
        validate();
      } catch (error) {
        pushLogEntry(`An error occurred: ${error}`, LOG_TYPES.ERROR);
        setErrorMessage('Sorry! something went wrong, please try again (demo: error)');
        isCheckoutButtonReady = true;
      } finally {
        setIsNotificationVisible(false);
      }
      return initResponse;
    }
    isCheckoutButtonReady = true;
  }, [buildInitPayload, threeDsSettings.managedOrdersIntegration]);

  const pushLogEntry = (text, type = 'default') => setLog((log) => [...log, { timestamp: Date.now(), text, type }]);

  const dataEntryFinished = async (token = null) => {
    const { amount, shipping, tax } = products[threeDsSettings.product];
    const totalAmount = parseInt(amount, 10) + parseInt(shipping, 10) + parseInt(tax, 10);
    const { currency, enableCorrelationId, recurringPaymentCurrentPaymentNumber } = threeDsSettings;
    const totalAmountWithCurrency = { amountLocalCurrency: totalAmount.toString(), currency: currency.code };
    const { cc, expiry, cvv } = formFields;

    const payment = {
      ...formFields,
      amount: currency.code === 'USD' ? totalAmount : totalAmountWithCurrency,
      recurringPaymentCurrentPaymentNumber,
      cardBrand:
        threeDsSettings.cardNetworkToUse === CARD_NETWORKS_TO_USE.DEFAULT.value
          ? undefined
          : threeDsSettings.cardNetworkToUse,
      ...(token ? { token } : { cc, expiry, cvv, bin: cc.slice(0, 6) }),
    };

    if (enableCorrelationId) {
      payment.correlationId = correlationId;
      delete payment.cc;
    }

    setIsLoading(true);

    pendingPayment = payment;
    await validate();
  };

  const validate = async () => {
    if ((!isCheckoutButtonReady && !threeDsSettings.tokenizationMode) || !pendingPayment) {
      return;
    }

    const payment = pendingPayment;
    pendingPayment = undefined;
    let response;
    let verificationResponse;
    // The merchant server should extract the ThreeDS object
    // from Forter's response and send it back to the client,
    // the demo server sends the full response for demo purposes:
    let threeDSResponse = {};
    if (!threeDsSettings.managedOrdersIntegration) {
      // Add the threeDSServerTransID to the payment object
      if (payment.correlationId) {
        delete payment.expiry;
        delete payment.cvv;
        delete payment.bin;
      }
      payment.threeDSServerTransID = threeDSServerTransID;
      pushLogEntry('(browser) POST 3ds-demo-store.forter.com/pay', LOG_TYPES.BROWSER);
      pushLogEntry(JSON.stringify(payment, null, 4), LOG_TYPES.PAYLOAD);
      pushLogEntry('(browser) ...Waiting for response...', LOG_TYPES.BROWSER);
      const { response: payResponse, requestPayload } = await send({
        path: 'pay',
        body: {
          payment,
          orderId,
          product: products[threeDsSettings.product].description,
          threeDsExecutor: threeDsSettings.executor,
        },
      });
      response = payResponse;
      pushLogEntry("(merchant server) POST 'adaptive-auth/orders/[orderId]'", LOG_TYPES.MERCHANT);
      pushLogEntry(JSON.stringify(requestPayload, null, 4), LOG_TYPES.PAYLOAD);
      pushLogEntry(
        '(merchant server) Response received, passing the response back to the browser:',
        LOG_TYPES.MERCHANT,
      );
      pushLogEntry(JSON.stringify(response, null, 4), LOG_TYPES.PAYLOAD);

      if (response.verificationMethod.verificationSpecificData) {
        threeDSResponse = response.verificationMethod.verificationSpecificData.ThreeDS;
        if (threeDSResponse.encodedChallengeRequest) {
          pushLogEntry('(browser) Response received:', LOG_TYPES.BROWSER);
          pushLogEntry(JSON.stringify(threeDSResponse, null, 4), LOG_TYPES.PAYLOAD);
        }
      }

      if (challengeDisplay.value === CHALLENGE_POSITIONS.AJAX_REDIRECT.value) {
        setisBackVisible(true);
        setPage('challenge');
      }
      if (challengeDisplay.value === CHALLENGE_POSITIONS.CHECKOUT.value) {
        setShowChallengeInCheckout(true);
        document.getElementById('challenge').scrollIntoView();
      }
      if (challengeDisplay.value === CHALLENGE_POSITIONS.MODAL.value) {
        setShowChallengeInModal(true);
      }
      setIsLoading(false);

      if (threeDSResponse.encodedChallengeRequest) {
        setNotificationMessage(NOTIFICATIONS.TRIGGER_CHALLENGE);
        setIsNotificationVisible(true);
        setTimeout(() => {
          setIsNotificationVisible(false);
        }, 3000);

        pushLogEntry(
          '(browser) Calling checkoutTools.tds.triggerChallengeIfNeeded(response, [challengeContainer], [callback])',
          LOG_TYPES.BROWSER,
        );
        pushLogEntry('(browser) ...Waiting for the user to submit the challenge...', LOG_TYPES.BROWSER);
        await sleep(3000);

        let challengeResult;
        try {
          setErrorMessage(undefined);
          challengeResult = await checkoutTools.triggerChallengeIfNeeded(
            threeDSResponse,
            threeDsSettings.customChallengeElement ? challengeContainer.current : null,
          );
        } catch (error) {
          pushLogEntry(`An error occurred: ${error}`, LOG_TYPES.ERROR);
          setErrorMessage('Sorry! something went wrong, please try again (demo: error)');
          return;
        }

        const { wasChallengePerformed, transStatus, cres } = challengeResult;

        // explanation for "error:undefined": this used to print a variable called "error", which was always undefined.
        // Unfortunately, we can't fix the log message as it will break our ee2e test suite
        pushLogEntry(
          `(browser) Callback called (error:undefined, wasChallengePerformed:${wasChallengePerformed}, transStatus:${transStatus}, cres:${cres}`,
          LOG_TYPES.BROWSER,
        );
        // Bringing the AV to the client, just for demo purposes
        setNotificationMessage(NOTIFICATIONS.TRIGGER_VERIFY);
        setIsNotificationVisible(true);
        pushLogEntry("(merchant server) POST 'adaptive-auth/3ds/verify/[orderId]'", LOG_TYPES.MERCHANT);
        pushLogEntry(JSON.stringify({ cres }, null, 4), LOG_TYPES.PAYLOAD);
        pushLogEntry(
          '(Forter) Verifying challenge with the 3DS server (issuer) and receiving the 3DS fields including the Authentication Value',
          LOG_TYPES.FORTER,
        );
        verificationResponse = await send({
          path: 'verify',
          body: {
            cres,
            orderId,
          },
        });
        setIsNotificationVisible(false);

        pushLogEntry('(merchant server) Response received: ', LOG_TYPES.MERCHANT);
        pushLogEntry(JSON.stringify(verificationResponse, null, 4), LOG_TYPES.PAYLOAD);
        pushLogEntry(
          '(browser)Challenge completed, using the CRES to retrieve the authentication value',
          LOG_TYPES.BROWSER,
        );
      }
    } else {
      // if managedOrdersIntegration
      if (threeDsSettings.networkTokenizationMode) {
        pushLogEntry('(browser) POST 3ds-demo-store.forter.com/upgrade-token-with-network-token', LOG_TYPES.BROWSER);
        pushLogEntry(`single use token: ${payment.token}`, LOG_TYPES.PAYLOAD);
        pushLogEntry('(browser) ...Waiting for response...', LOG_TYPES.BROWSER);
        const { upgradeResponse, provisionCryptogramResponse } = await send({
          path: 'upgrade-token-with-network-token',
          body: {
            token: payment.token,
            siteSecret: threeDsSettings.headers.proxyAuthorizationHeader,
            siteId: threeDsSettings.headers.siteIdHeader,
          },
        });

        payment.token = upgradeResponse.token;
        pushLogEntry("(merchant server) POST 'v1/tokenization/upgrade'", LOG_TYPES.MERCHANT);
        pushLogEntry(JSON.stringify(upgradeResponse, null, 4), LOG_TYPES.PAYLOAD);
        pushLogEntry("(merchant server) POST 'v1/tokenization/provision-cryptogram'", LOG_TYPES.MERCHANT);
        pushLogEntry(JSON.stringify(provisionCryptogramResponse, null, 4), LOG_TYPES.PAYLOAD);
      }

      pushLogEntry('(browser) POST 3ds-demo-store.forter.com/create-managed-order', LOG_TYPES.BROWSER);
      pushLogEntry(JSON.stringify(payment, null, 4), LOG_TYPES.PAYLOAD);
      pushLogEntry('(browser) ...Waiting for response...', LOG_TYPES.BROWSER);
      const { response: managedOrderResponse, requestPayload } = await send({
        path: 'create-managed-order',
        body: {
          payment,
          product: products[threeDsSettings.product].description,
          threeDsExecutor: threeDsSettings.executor,
        },
      });
      orderId = requestPayload.orderId;
      const { managedOrderToken } = managedOrderResponse;
      pushLogEntry("(merchant server) POST 'managed/orders/[orderId]'", LOG_TYPES.MERCHANT);
      pushLogEntry(JSON.stringify(requestPayload, null, 4), LOG_TYPES.PAYLOAD);
      pushLogEntry(
        '(merchant server) Response received, passing the response back to the browser:',
        LOG_TYPES.MERCHANT,
      );
      pushLogEntry(JSON.stringify(managedOrderResponse, null, 4), LOG_TYPES.PAYLOAD);
      setIsLoading(false);
      if (managedOrderToken) {
        if (challengeDisplay.value === CHALLENGE_POSITIONS.AJAX_REDIRECT.value) {
          setisBackVisible(true);
          setPage('challenge');
        }
        if (challengeDisplay.value === CHALLENGE_POSITIONS.CHECKOUT.value) {
          setShowChallengeInCheckout(true);
          document.getElementById('challenge').scrollIntoView();
        }
        if (challengeDisplay.value === CHALLENGE_POSITIONS.MODAL.value) {
          setShowChallengeInModal(true);
        }

        pushLogEntry(
          '(browser) Calling checkoutTools.managedOrders.managedOrder(managedOrderToken, { challengeContainer })',
          LOG_TYPES.BROWSER,
        );
        await window.checkoutTools.managedOrders.manageOrder(managedOrderToken, {
          challengeContainer() {
            setNotificationMessage(NOTIFICATIONS.TRIGGER_CHALLENGE);
            setIsNotificationVisible(true);
            setTimeout(() => {
              setIsNotificationVisible(false);
            }, 3000);
            return threeDsSettings.customChallengeElement ? challengeContainer.current : null;
          },
        });

        pushLogEntry('(browser) Order managed! Fetching results from server...', LOG_TYPES.BROWSER);
        pushLogEntry('(browser) POST 3ds-demo-store.forter.com/get-managed-order-results', LOG_TYPES.BROWSER);
        pushLogEntry(JSON.stringify({ managedOrderToken }, null, 4), LOG_TYPES.PAYLOAD);
        pushLogEntry('(browser) ...Waiting for response...', LOG_TYPES.BROWSER);
        const { response: managedOrderResultsResponse, requestPayload: resultsRequestPayload } = await send({
          path: 'get-managed-order-results',
          body: {
            managedOrderToken,
            orderId,
          },
        });
        pushLogEntry("(merchant server) POST 'managed/orders/[orderId]/results'", LOG_TYPES.MERCHANT);
        pushLogEntry(JSON.stringify(resultsRequestPayload, null, 4), LOG_TYPES.PAYLOAD);
        pushLogEntry(
          '(merchant server) Response received, passing the response back to the browser:',
          LOG_TYPES.MERCHANT,
        );
        pushLogEntry(JSON.stringify(managedOrderResultsResponse, null, 4), LOG_TYPES.PAYLOAD);
        setIsLoading(false);
        response = managedOrderResultsResponse;
        verificationResponse = managedOrderResultsResponse;
      } else {
        response = managedOrderResponse;
        verificationResponse = managedOrderResponse;
      }
    }
    const { forterDecision, recommendation } = verificationResponse || response;
    const verificationMethod = (verificationResponse || response).verificationMethod || {};
    const threeDsResults = (verificationMethod.verificationSpecificData || {}).ThreeDS;
    if (forterDecision === FORTER_DECISIONS.APPROVE) {
      // eslint-disable-next-line max-len,prettier/prettier
      if ((threeDsResults || {}).authenticationValue) {
        pushLogEntry('-----------------------------', LOG_TYPES.MERCHANT);
        pushLogEntry('3DS AUTHENTICATION COMPLETED', LOG_TYPES.MERCHANT);
        pushLogEntry('-----------------------------', LOG_TYPES.MERCHANT);
        pushLogEntry(
          `(merchant server) Calling payment gateway with authenticationValue: ${threeDsResults.authenticationValue}`,
          LOG_TYPES.MERCHANT,
        );
      } else {
        pushLogEntry('-----------------------------', LOG_TYPES.MERCHANT);
        pushLogEntry('TRANSACTION APPROVED (WITHOUT 3DS AUTHENTICATION)', LOG_TYPES.MERCHANT);
        pushLogEntry('-----------------------------', LOG_TYPES.MERCHANT);

        if (recommendation && recommendation.startsWith('REQUEST_SCA_EX')) {
          pushLogEntry(
            '(merchant server) Calling payment gateway with exemption/exclusion request',
            LOG_TYPES.MERCHANT,
          );
        } else {
          pushLogEntry('(merchant server) Calling payment gateway', LOG_TYPES.MERCHANT);
        }
      }
      setPage('thank-you');
    } else if (forterDecision === FORTER_DECISIONS.DECLINE) {
      pushLogEntry('-----------------------------', LOG_TYPES.ERROR);
      if (threeDsResults) {
        pushLogEntry('3DS AUTHENTICATION COMPLETED WITH A DECLINE', LOG_TYPES.ERROR);
        setErrorMessage(
          'Sorry! something went wrong, please try again with a different credit card (demo: 3DS Authentication Failure)',
        );
      } else {
        pushLogEntry('TRANSACTION DECLINED (WITHOUT 3DS AUTHENTICATION)', LOG_TYPES.ERROR);
        setErrorMessage(
          'Sorry! something went wrong, please try again with a different credit card (demo: Forter decline)',
        );
      }
      pushLogEntry('-----------------------------', LOG_TYPES.ERROR);
      navigateHome();
    }

    updateThreeDsSettings({ challengePosition: CHALLENGE_POSITIONS.AJAX_REDIRECT.value });
    setChallengeDisplay(CHALLENGE_POSITIONS.AJAX_REDIRECT);
    setShowChallengeInCheckout(false);
    setShowChallengeInModal(false);
  };

  const onResetForm = () => {
    setFormFields(formHelper.getFormInitObj());
  };

  const reset = () => {
    isCheckoutButtonReady = false;
    threeDSServerTransID = undefined;
    setIsLoading(false);
    navigateHome();
  };

  const navigateHome = () => {
    setisBackVisible(false);
    setPage('data-entry');
  };

  // Replacing the current flow with a new one
  const onFlowChange = (flowType) => {
    const flow = typeof flowType === 'string' ? flowType : threeDsSettings.flow;
    const { product } = FLOW_DATA[flow] || {};
    let disableProductSelection = false;

    if (product) {
      updateThreeDsSettings({ product });
      disableProductSelection = true;
    }

    setErrorMessage(undefined);
    if (flow) {
      updateThreeDsSettings({
        flow,
        disableProductSelection,
      });
    }
  };

  const onFormInputChange = ({ id, value }) => {
    setFormFields({ ...formFields, [id]: value });
  };

  const setData = async (flowType) => {
    try {
      const flow = FLOW_DATA[flowType];
      if (flow) {
        const { cc, email, expiry = '10/27', firstName = 'John', lastName = 'Doe' } = flow;

        const updatedFormFields = {
          ...formFields,
          cc,
          expiry,
          cvv: '456',
          email,
          firstName,
          lastName,
          address: '350 5th Avenue, New York, NY, 10118',
          zipCode: '10118',
        };
        setFormFields(updatedFormFields);
      }
    } catch (err) {
      pushLogEntry(`An error occurred: ${err}`, LOG_TYPES.ERROR);
      setNotificationMessage('');
      setIsNotificationVisible(false);
    }
  };

  const closeModal = () => {
    setShowChallengeInModal(false);
    reset();
  };

  const renderChallenge = () => {
    const isRedirect = page === 'challenge' && challengeDisplay.value === CHALLENGE_POSITIONS.AJAX_REDIRECT.value;
    const isCheckout = challengeDisplay.value === CHALLENGE_POSITIONS.CHECKOUT.value && showChallengeInCheckout;
    const isModal = challengeDisplay.value === CHALLENGE_POSITIONS.MODAL.value && showChallengeInModal;
    return threeDsSettings.customChallengeElement
      ? ChallengeWindow(isRedirect, isCheckout, isModal, challengeContainer, closeModal)
      : '';
  };

  const renderMainPage = () => {
    if (page === 'data-entry') {
      return (
        <div className="data-entry-container">
          <DataEntryPage
            logMessage={(message) => pushLogEntry(message)}
            key={`${threeDsSettings.tokenizationMode}-${threeDsSettings.forterTokenizationEnv}`}
            onResetForm={onResetForm}
            isLoading={isLoading}
            formFields={formFields}
            onFormInputChange={onFormInputChange}
            onFinish={dataEntryFinished}
            errorMessage={errorMessage}
            tokenizationMode={threeDsSettings.tokenizationMode}
            tokenizationEnv={threeDsSettings.forterTokenizationEnv}
            tokenizationAuthToken={tokenizationAuthToken}
          />
          <div className="cart">
            <OrderTable product={threeDsSettings.product} currency={threeDsSettings.currency} />
            <div className={`powered-by ${showSideMenu ? 'is-hidden' : ''}`}>
              <div>Powered By</div>
              <div>
                <img src={logo} alt="logo" width={75} />
              </div>
            </div>
            {challengeDisplay.value === 'CHECKOUT' ? renderChallenge() : ''}
          </div>
        </div>
      );
    }
  };

  const onBackPressed = () => {
    onResetForm();
    setLog([]);
    setCorrelationId(false);
    updateThreeDsSettings({
      challengePosition: challengeDisplay.value,
      flow: '',
      enableCorrelationId: false,
      disableProductSelection: false,
    });
    reset();
  };

  const hideSideMenu = () => {
    setShowSideMenu(false);
  };

  return (
    <div>
      <input readOnly checked={showSideMenu} type="checkbox" id="drawer-toggle" name="drawer-toggle" />
      <div className={`${showSideMenu ? 'console-open-btn-hide' : 'console-open-btn'}`}>
        <div
          onClick={() => {
            setShowSideMenu(!showSideMenu);
          }}
          className={`button small is-dark ${hasTooltip} is-tooltip-left`}
          data-tooltip="Collapse Log"
        >
          <FontAwesomeIcon icon={faTerminal} />
        </div>
      </div>
      <Console
        updateThreeDsSettings={updateThreeDsSettings}
        log={log}
        hideSideMenu={hideSideMenu}
        onResetForm={onResetForm}
        onFlowChange={onFlowChange}
        threeDsSettings={threeDsSettings}
        setChallengeDisplay={setChallengeDisplay}
        onClearLogPressed={() => setLog([])}
      />
      <div id="page-content">
        <StoreHeader isBackVisible={isBackVisible} reset={onBackPressed} />
        {renderMainPage()}
        {page === 'thank-you' && <ThankYouPage onBackPressed={onBackPressed} />}
        {challengeDisplay.value !== 'CHECKOUT' ? renderChallenge() : ''}
      </div>
      {isNotificationVisible && <Notification notificationMessage={notificationMessage} />}
    </div>
  );
}

export default App;
