module.exports = {
  DEV_SERVER: 'http://localhost:9500',
  API_ROUTE: '/.netlify/functions/server',
  HEADER_SETTINGS_TO_HEADERS: {
    proxyAuthorizationHeader: 'detokenization-proxy-authorization',
    authorizationHeader: 'authorization',
    siteIdHeader: 'siteid',
    apiVersionHeader: 'api_version',
    acquirerDataHeader: 'acquirer_data',
    disableSchemaHeader: 'x-forter-toggle-disable-schema',
    additionalInformationHeader: 'additional_information',
    additionalIdentifiersHeader: 'additional_identifiers',
    forterSubMerchantSiteIdHeader: 'x-forter-sub-merchant-siteid',
  },
};
