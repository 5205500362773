import { useEffect, useRef, useState } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const useForterCollect = (options) => {
  const forterCollectRef = useRef(null);
  const [, setFakeState] = useState(false);

  useEffect(() => {
    const func = async () => {
      if (!window.checkoutTools.collect) {
        throw new Error('checkouttools SDK was not found, did you include it via <script> tag?');
      }

      if (!forterCollectRef.current && options.authToken) {
        const newForterCollect = await window.checkoutTools.collect.init(options);

        if (!forterCollectRef.current) {
          forterCollectRef.current = newForterCollect;
        }
        setFakeState(true);
      }
    };

    func();
  }, [options.authToken]);

  return forterCollectRef.current;
};
